import React from 'react';

const Six = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="56.909"
    height="40.65"
    viewBox="0 0 20.909 40.65"
  >
    <defs>
      <clipPath>
        <rect width="56.909" height="40.65" fill="currentColor" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)">
      <path
        d="M19.792,28.09a20.814,20.814,0,0,1-.7,5.13,11.309,11.309,0,0,1-1.9,3.952A8.734,8.734,0,0,1,14.117,39.7a9.161,9.161,0,0,1-4.109.9,8.79,8.79,0,0,1-7.353-3.421Q0,33.754,0,26.744a42.52,42.52,0,0,1,1.2-10.6A29.137,29.137,0,0,1,4.47,8.13a18.78,18.78,0,0,1,4.861-5.41A15.447,15.447,0,0,1,15.31,0l1.118,3.252a13,13,0,0,0-4.555,2.242A16.832,16.832,0,0,0,8.261,9.307a22.244,22.244,0,0,0-2.583,5.046,26.8,26.8,0,0,0-1.416,6,6.623,6.623,0,0,1,2.421-2.635,7.468,7.468,0,0,1,4.2-1.121,8,8,0,0,1,6.429,2.859q2.421,2.859,2.477,8.634m-4.037.392q0-8.242-5.915-8.242a5.255,5.255,0,0,0-3.627,1.346,7.482,7.482,0,0,0-2.12,3.028,9.313,9.313,0,0,0-.112,1.43q0,.7.056,1.317a14.91,14.91,0,0,0,.307,3.56,10.766,10.766,0,0,0,1.116,3.056,6.466,6.466,0,0,0,1.9,2.159,4.549,4.549,0,0,0,2.707.813,4.831,4.831,0,0,0,4.1-2.243,10.611,10.611,0,0,0,1.591-6.223"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Six;
